<template>
  <y_layout>
    <el-row>
      <el-col :span="1">
        <div class="flex-def flex-zEnd" style="width: 100%;margin-top: 2.5rem">
          <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom tab-box">
            <div :class="$route.name === 'agentFinanceInfo' ? 'active' : ''"
                 @click="$router.push({name:'agentFinanceInfo',params:$route.params})" class="y-pointer"
                 style="padding: 1rem 0;">账<br>户<br>信<br>息
            </div>
            <div :class="$route.name === 'agentFinanceCash' ? 'active' : ''"
                 @click="$router.push({name:'agentFinanceCash',params:$route.params})" class="y-pointer"
                 style="padding: 1rem 0;border-top: 1px solid #ededed">提<br>现<br>记<br>录
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="23">
        <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
          <router-view/>
        </el-card>
      </el-col>
    </el-row>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
}
</script>

<style scoped>

.y-pointer:hover,
.active {
  color: #5D60F6;
}

.tab-box {
  border-radius: 10px 0 0 10px;
  border: 1px solid #ededed;
  font-size: 12px;
  background-color: #FFFFFF;
  width: 2rem;
  color: #888888;
  font-weight: 600;
}
</style>